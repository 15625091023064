import { Session } from "./type";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const DEFAULT_COUNTRY = "vn";
export const DEFAULT_LOCATION = "han_1nx";
export const DEFAULT_MENU = "v1";

const initialState: Session = {
  // session_id: undefined,
  country: undefined,
  location: undefined,
  table: undefined
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<Session>) => {
      // state.session_id = action.payload.session_id;
      state.country = action.payload.country;
      state.location = action.payload.location;
      state.table = action.payload.table;
    },
    updateLocation: (state, action: PayloadAction<string>) => {
      state.location = action.payload;
    },
    updateTable: (state, action: PayloadAction<string>) => {
      state.table = action.payload;
    },
    updateCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload;
    }
  }
});

export const {
  setSession,
  updateLocation,
  updateCountry,
  updateTable
} = sessionSlice.actions;

export default sessionSlice.reducer;
