import SizeItem from "containers/drawer/views/SizeItem";
import { convertContentToDisplaySize, SizeType } from "models/Product";

type SizeTabbarProps = {
  pricing: any;
  selectedSize: SizeType;
  onChangeSize: (size: SizeType) => void;
  className?: string;
};

export default function SizeTabbar(props: SizeTabbarProps) {
  const { selectedSize, onChangeSize, pricing = [], className = "" } = props;
  return (
    <div className={`flex w-full flex-row items-baselines justify-between px-10 ${className}`}>
      {pricing?.map((size) => {
        return (
          <SizeItem
            key={size.size}
            label={`${convertContentToDisplaySize(size.size)} - ${size.value / 1000}k`}
            handleChange={onChangeSize}
            value={size.size}
            checked={selectedSize === size.size}
          />
        );
      })}
    </div>
  );
}
