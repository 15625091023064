import React, { useCallback, useEffect, useState, useRef } from "react";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { closeBottomSheet } from "../../store/bottomSheetSlice";
import Products from "../../containers/products";
import { Product } from "../../models/Product";
import { useLanguage } from "../../hooks/useLanguage";
import VariantSelection from "./VariantSelection";
import OrderItem from "components/order/OrderItem";
import { ProductItem } from "store/type";
import {
  ProductDetailPage,
  ProductItemSource,
} from "pages/ProductDetailPage/ProductDetailPage";
import { useCustomization } from "hooks/useCustomization";
import { first, isEmpty } from "lodash";

export interface ProductDetailBottomSheetProps {}

export default function ProductDetailBottomSheet(
  props: ProductDetailBottomSheetProps,
) {
  const { open, product } = useAppSelector((root) => root.bottomSheet);
  const { name, id } = (product as Product) || {};
  const currentOrder = useAppSelector((root) => root.order.currentOrder);
  const currentItems = currentOrder?.items || [];

  const quantity = currentItems.reduce((total, item) => {
    if (item.item_id === id) {
      return item.quantity + total;
    }
    return total;
  }, 0);

  const productVariants = currentItems.filter((item) => item.item_id === id);

  const dispatch = useAppDispatch();
  const sheetRef = useRef<BottomSheetRef>(null);

  const [selectedProduct, setSelectedProduct] = useState<ProductItem>(
    {} as ProductItem,
  );

  const {
    osRef,
    key,
    isFavorite,
    isLoading,
    product: selectedProductDetail,
    setIsFavorite,
    CustomizationView,
    UpdateQuantityView,
  } = useCustomization({
    productId: id,
    customizationState: {
      item: selectedProduct,
      from: quantity === 0 ? ProductItemSource.NONE : ProductItemSource.ORDER,
    },
    onConfirmClicked: () => {
      dispatch(closeBottomSheet());
      setSelectedProduct({} as ProductItem);
    },
  });

  const renderCustomization = () => {
    return (
      <div className="flex flex-col gap-2">
        <CustomizationView />
        <UpdateQuantityView />
      </div>
    );
  };

  const renderVariants = () => {
    return (
      <div className="flex flex-col gap-2">
        {productVariants?.map((item: ProductItem, index: number) => (
          <OrderItem
            key={index}
            item={item}
            onClick={() => setSelectedProduct(item)}
            showDivide={index < productVariants.length - 1}
          />
        ))}
      </div>
    );
  };

  const shouldRenderCustomization = !isEmpty(selectedProduct) || quantity === 0;

  return (
    <BottomSheet
      open={open}
      ref={sheetRef}
      maxHeight={740}
      expandOnContentDrag
      onDismiss={() => {
        dispatch(closeBottomSheet());
        setSelectedProduct({} as ProductItem);
      }}
    >
      <div
        className={`flex h-fit w-full flex-col items-center ${shouldRenderCustomization ? "p-0" : "p-4"}`}
      >
        <div className="flex w-full max-w-screen-md flex-col">
          {shouldRenderCustomization ? renderCustomization() : renderVariants()}
          {/*<VariantSelection />*/}
        </div>
      </div>
    </BottomSheet>
  );
}
