import PuffLoader from "react-spinners/PuffLoader";

export type SanButtonProps = {
  className?: string;
  title?: string;
  loading?: boolean;
  onClick?: () => void;
  RightIcon?: React.FC<{
    className?: string;
    color?: string;
    width?: string;
    height?: string;
  }>;
  type?: "outline" | "solid" | "disabled";
  disabled?: boolean;
};

export default function SanButton(props: SanButtonProps) {
  const {
    type = "solid",
    title,
    loading,
    onClick,
    RightIcon,
    disabled = false,
  } = props;
  const buttonStyleMapping = {
    solid: "bg-brand-1",
    outline: "border-[1px] border-brand-1 border-solid bg-white",
    disabled: "bg-border-1",
  };
  const titleStyleMapping = {
    solid: "text-white",
    outline: "text-brand",
    disabled: "text-tertiary",
  };

  return (
    <div
      onClick={() => {
        !disabled && onClick?.();
      }}
      className={`flex h-11 w-full cursor-pointer flex-row
         items-center justify-center rounded-[40px] hover:bg-brand-tint ${buttonStyleMapping[type]} ${props?.className || ""} `}
    >
      {loading ? (
        <PuffLoader color="#fff" size={32} />
      ) : (
        <div className="flex flex-row items-center gap-2">
          <p
            className={`text-14px font-semibold text-brand-4 ${titleStyleMapping[type]}`}
          >
            {title || ""}
          </p>
          {RightIcon && (
            <RightIcon width={"13px"} height={"13px"} color={"#fff"} />
          )}
        </div>
      )}
    </div>
  );
}
