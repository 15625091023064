import { useState } from "react";

type CustomizationTabbarProps = {
  active?: number;
  setActive?: (active: number) => void;
  shouldShowTopping?: boolean;
};

export default function CustomizationTabbar(props: CustomizationTabbarProps) {
  const { shouldShowTopping = true } = props;
  const tabs = [
    {
      id: 0,
      title: "Đường và đá",
      type: "custom",
    },
    shouldShowTopping && {
      id: 1,
      title: "Topping",
      type: "topping",
    },
  ];

  const { active = 0, setActive } = props;

  return (
    <div className="center flex w-full gap-[8px] rounded-[40px] bg-neutrals-divider p-1">
      {tabs.map((tab) => tab && (
        <CustomizationTabbarItem
          tab={tab}
          isActive={active === tab.id}
          setActive={setActive}
          key={`customizable-item-${tab.id}`}
        />
      ))}
    </div>
  );
}

type CustomizationTabbarItemProps = {
  tab: {
    id: number;
    title: string;
    type: string;
  };
  isActive?: boolean;
  setActive?: (active: number) => void;
};

export function CustomizationTabbarItem(props: CustomizationTabbarItemProps) {
  const { tab } = props;
  return (
    <div
      className={`line-clamp-1 flex h-10 w-full cursor-pointer items-center justify-center rounded-[40px] px-4 text-14px font-normal ${props.isActive ? "border-[1px] border-solid border-gray-c5 bg-white text-brand-4" : "text-gray-menu"}`}
      onClick={() => props.setActive?.(tab.id)}
    >
      <p className="line-clamp-1"> {tab.title}</p>
    </div>
  );
}
