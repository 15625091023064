import React from "react";
import { Gap } from "../../components/gap/Gap";
import { useLanguage } from "hooks/useLanguage";
import PageHeader from "components/PageHeader";
import Markdown from "react-markdown";
import { s3ImageUrl } from "utils/FileUtils";

const markdown = `

Chào mừng bạn đến với **Sắn**, thương hiệu lấy cảm hứng từ nghệ thuật tranh Đông Hồ - một nét đẹp văn hóa truyền thống Việt Nam. Cái tên “Sắn” không chỉ là một nguyên liệu quan trọng trong tranh Đông Hồ mà còn tượng trưng cho sự gần gũi, mộc mạc và tinh hoa văn hóa dân tộc.  

Với sứ mệnh kết nối giá trị truyền thống và hiện đại, **Sắn** sử dụng công nghệ tiên tiến để nâng cao trải nghiệm của người dùng. Chúng tôi tin rằng văn hóa và công nghệ không chỉ có thể song hành, mà còn giúp nhau phát triển mạnh mẽ hơn.  

## **Tầm Nhìn và Sứ Mệnh**  
- **Tầm nhìn**: Trở thành cầu nối giữa văn hóa truyền thống và cuộc sống hiện đại thông qua công nghệ.  
- **Sứ mệnh**: Mang đến những trải nghiệm văn hóa đặc sắc của dân tộc, kết hợp với sự tiện ích và hiệu quả của công nghệ để phục vụ khách hàng trong nhiều lĩnh vực.  

## **Giá Trị Cốt Lõi**  
- **Đậm Đà Bản Sắc**: Tôn vinh và lan tỏa vẻ đẹp của văn hóa Việt Nam.  
- **Đổi Mới Sáng Tạo**: Không ngừng ứng dụng công nghệ mới để cải thiện chất lượng dịch vụ.  
- **Trải Nghiệm Người Dùng**: Đặt khách hàng làm trọng tâm, mang đến sự tiện ích và hài lòng tối đa.  

Hãy đồng hành cùng **Sắn** để khám phá một hành trình mới, nơi văn hóa và công nghệ hội tụ để tạo nên những giá trị bền vững cho cộng đồng.  

## **Liên Hệ**  
- **Tên doanh nghiệp**: CÔNG TY TNHH SẮN CAFE
- **Mã số thuế**: 0110816792
- **Địa chỉ**: Căn SH36, Khu nhà ở Rue de Charme, Dự án Khu đô thị mới Hạ Đình, Ngõ 214
Nguyễn Xiển, Xã Tân Triều, Huyện Thanh Trì, Thành phố Hà Nội, Việt Nam
`;

const AboutPage: React.FC = () => {
  const { t } = useLanguage();
  return (
    <main
      className={`flex h-screen w-full flex-col items-center overflow-hidden`}
      style={{
        minHeight: "-webkit-fill-available",
        WebkitOverflowScrolling: "touch",
      }}
    >
      <div className="relative w-full max-w-screen-md">
        <img
          src={s3ImageUrl("bg_san_no_logo.png")}
          alt=""
          className={`z-0 w-full`}
        />
        <div className="absolute bottom-0 left-0 right-0 top-0 z-0">
          <PageHeader transparent title={t("aboutUs")} />
          <div className="mx-4 mt-40 flex h-full flex-col items-center rounded-t-2xl bg-white p-4 md:mx-8">
            <Gap size={"S"} />
            <img
              src={s3ImageUrl("san-logo.png")}
              alt=""
              className="h-12 w-12"
            />
            <Markdown
              components={{
                h1(props) {
                  const { node, ...rest } = props;
                  return (
                    <h1 className={`text-3xl my-3 text-brand-4`} {...rest} />
                  );
                },
                h2(props) {
                  const { node, ...rest } = props;
                  return (
                    <h2 className={`my-1 text-18px text-brand-4`} {...rest} />
                  );
                },
                h3(props) {
                  const { node, ...rest } = props;
                  return (
                    <h3 className={`text-lg my-2 text-brand-4`} {...rest} />
                  );
                },
                ul(props) {
                  const { node, ...rest } = props;
                  return (
                    <span
                      style={{ color: "#31261D" }}
                      className="text-justify text-[14px]"
                      {...rest}
                    />
                  );
                },
                p(props) {
                  const { node, ...rest } = props;
                  return (
                    <span
                      style={{ color: "#31261D" }}
                      className="text-justify text-[14px]"
                      {...rest}
                    />
                  );
                },
                a(props) {
                  const { node, ...rest } = props;
                  return props.href?.includes("@") ? (
                    <a
                      href={`mailto:${props.href}`}
                      style={{ color: "deepskyblue" }}
                      {...rest}
                    />
                  ) : (
                    <a
                      href={`${props.href}`}
                      style={{ color: "deepskyblue" }}
                      {...rest}
                    />
                  );
                },
              }}
              className={`mt-6 text-brand-4 xl:mt-8`}
            >
              {markdown}
            </Markdown>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AboutPage;
