import ChevronLeft from "../../assets/icons/chevron-left";
import HeartIcon from "../../assets/icons/heart-icon";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop";
import { Gap } from "../../components/gap/Gap";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Skeleton } from "antd";
import { useCustomization } from "hooks/useCustomization";
import { s3ImageUrl } from "utils/FileUtils";
import HoverVideo from "components/HoverVideo";

export enum ProductItemSource {
  "ORDER" = "ORDER",
  "MENU" = "MENU",
  "NONE" = "NONE",
}

export interface ProductDetailPageProps {}

export function ProductDetailPage() {
  const navigate = useNavigate();
  const {
    key,
    isFavorite,
    isLoading,
    product,
    setIsFavorite,
    CustomizationView,
    UpdateQuantityView,
  } = useCustomization({
    onConfirmClicked: () => {
      navigate(-1);
    },
  });
  const { image } = product || {};

  return (
    <div className="relative flex h-screen w-full flex-col items-center">
      <ScrollToTop />
      <div className="max-w-screen-md">
        <div className={`relative w-full`}>
          <div className="absolute left-0 right-0 z-10 flex w-full flex-row justify-between px-4 py-3">
            <button
              className={`flex h-9 w-9 flex-col items-center justify-center rounded-full bg-white`}
              onClick={() => {
                if (key === "default") navigate("/");
                else navigate(-1);
              }}
              aria-label="close"
            >
              <ChevronLeft />
            </button>
            <button
              className={`flex h-9 w-9 flex-col items-center justify-center rounded-full
             ${isFavorite ? "bg-secondary-7" : "bg-white"}`}
              onClick={() => setIsFavorite(!isFavorite)}
              aria-label="close"
            >
              <HeartIcon color={isFavorite ? "#fff" : "#858585"} />
            </button>
          </div>

          {isLoading ? (
            <Skeleton.Node active style={{ height: 354, width: "100vw" }} />
          ) : (
            // <RemoteLottie
            //   animationUrl={r2ImageUrl("11.json")}
            //   width={'100%'}
            //   height={354}
            //   autoplay
            // />
            <HoverVideo
              videoUrl={s3ImageUrl("1.mp4")}
              autoplay
              loop={false}
              className="object-cover object-center"
            />
            // <img
            //   className="h-[354px] w-screen object-cover object-center"
            //   src={image}
            //   alt={`${name}-img`}
            // />
          )}
          {/* <div className="bottom-0 right-2 scale-75">
            <DrinkIllustrator
              isLoading={isLoading}
              size={'small'}
              isHot={true}
              icePercent={50}
            />
          </div> */}
        </div>
        {CustomizationView()}
        <Gap size={"L"} />
        {UpdateQuantityView()}
      </div>
    </div>
  );
}
