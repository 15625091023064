import { createCode, resendCode } from "supertokens-web-js/recipe/passwordless";

export const usePasswordlessLogin = () => {
  async function sendOtpViaEmail(email: string) {
    return createCode({
      email,
    });
  }

  async function sendOTPViaPhoneNumber(phoneNumber: string) {
    return createCode({
      phoneNumber,
    });
  }

  async function handleResendOtp() {
    return resendCode();
  }

  return {
    sendOtpViaEmail,
    sendOTPViaPhoneNumber,
    handleResendOtp,
  };
};
