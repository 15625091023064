import { useEffect } from "react";
import {
  getGuestToken,
  getRefreshToken,
  getToken,
} from "store/api/baseAxiosQuery";
import { setIsGuest, setLogin } from "store/authSlice";
import { useAppDispatch } from "store/hook";
import { useHandleLogin } from "./useHandleLogin";

export type UseUserProfileProps = {};

export function useUserProfile(props: UseUserProfileProps) {
  const dispatch = useAppDispatch();

  const { loginAsGuest } = useHandleLogin({});

  const checkLoginState = () => {
    const accessToken = getToken();
    const refreshToken = getRefreshToken();
    const guestToken = getGuestToken();
    if (accessToken && refreshToken) {
      dispatch(setLogin(true));
    } else {
      if (guestToken) {
        console.log("Login as a GUEST");
        dispatch(setIsGuest(true));
      } else {
        loginAsGuest();
      }
    }
  };
  return { checkLoginState };
}
