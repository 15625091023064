import React, { useState } from "react";
import RadioSelector from "../selector/RadioSelector";
import { Radio, type RadioChangeEvent } from "antd";
import IceSelector from "../../containers/drawer/views/IceSelector";
import HotIceSelector from "components/selector/HotIceSelector";
import { Gap } from "components/gap/Gap";
import ItemSlider from "components/slider";

export interface VariantSelectionProps {
  icePercent: number;
  setIcePercent: (value: number) => void;
  isHot: boolean;
  setIsHot: (value: boolean) => void;
  scrollToIceSelector?: () => void;
  className?: string;
  sugar: number;
  onChangeSugar: (e: number) => void;
}

export default function VariantSelection(props: VariantSelectionProps) {
  const {
    icePercent,
    setIcePercent,
    isHot,
    setIsHot,
    scrollToIceSelector = () => null,
    className,
  } = props;
  const radioItems = [
    {
      value: true,
      label: "Nóng/ Ấm",
    },
    {
      value: false,
      label: "Lạnh/ Đá",
    },
  ];

  const [radioValue, setRadioValue] = useState(isHot);

  const onChangeRadio = (e: { value: boolean }) => {
    const hot = e.value;
    setRadioValue(hot);
    // !hot && scrollToIceSelector?.();
    setIsHot(hot);
  };

  return (
    <div className={`${className || ""}`}>
       <p className={`mt-2 text-[14px] font-bold`}>Lượng đường</p>
      <ItemSlider
        title="Đường"
        labels={[0, 25, 50, 75, 100]}
        value={props.sugar}
        onChangeValue={props.onChangeSugar}
      />
      <p className={`mb-1 mt-2 text-[14px] font-bold`}>Nóng/Đá</p>
      <Gap size={"XS"} />
      <div
        className={`rounded-[16px] border-[1px] border-solid border-[#E0E0E0] p-3`}
      >
        <HotIceSelector
          items={radioItems}
          value={radioValue}
          onChange={onChangeRadio}
        />
      </div>
      <div
        className={`${!isHot ? "opacity-100" : "opacity-0"} transition-opacity delay-150 duration-300 ease-in-out`}
      >
        <p className={`mb-1 mt-4 text-[14px] font-bold`}>Lượng đá</p>
        <div className={`flex w-full flex-row justify-between px-10`}>
          {[25, 50, 75]?.map((size) => {
            return (
              <IceSelector
                key={size}
                label={`${size}%`}
                handleChange={() => {
                  setIcePercent(size);
                }}
                value={size}
                checked={icePercent === size}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
