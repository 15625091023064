import { BaseQueryFn } from "@reduxjs/toolkit/query/react";
import axios, { AxiosRequestConfig, AxiosError } from "axios";

export const getToken = () => localStorage.getItem("accessToken");
export const getRefreshToken = () => localStorage.getItem("refreshToken");
export const getGuestToken = () => localStorage.getItem("guestToken");

export const setAccessToken = (accessToken: string) => {
  localStorage.setItem("accessToken", accessToken);
};

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem("refreshToken", refreshToken);
};

export const setGuestToken = (guestToken: string) => {
  localStorage.setItem("guestToken", guestToken);
};

export const clearTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

export const clearGuestToken = () => {
  localStorage.removeItem("guestToken");
};

// Function to refresh the JWT
const refreshToken = async () => {
  const refreshTokenValue = getRefreshToken();
  if (!refreshTokenValue) throw new Error("No refresh token available");
  const response = await axios.post(
    `${process.env.REACT_APP_CONSUMER_BASE_URL}/auth/session/refresh`,
    {
      token: refreshTokenValue,
    },
  );
  const { accessToken, refreshToken: newRefreshToken } = response.data;
  setAccessToken(accessToken);
  setRefreshToken(newRefreshToken);
  return accessToken;
};

const baseAxiosQuery =
  ({
    baseUrl,
  }: {
    baseUrl: string;
  }): BaseQueryFn<AxiosRequestConfig, unknown, unknown> =>
  async (axiosConfig) => {
    const instance = axios.create({
      baseURL: baseUrl,
    });

    // Add interceptor for request
    instance.interceptors.request.use(
      (config) => {
        const token = getToken();
        const guestToken = getGuestToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        } else if (guestToken) {
          config.headers.Authorization = `Bearer ${guestToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    // Add interceptor for response
    instance.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if (error.response?.status === 401) {
          // If token is expired or invalid, try to refresh
          try {
            const newAccessToken = await refreshToken();

            // Ensure error.config is defined before using it
            if (error.config) {
              // Retry the original request with the new access token
              error.config.headers.Authorization = `Bearer ${newAccessToken}`;
              return instance(error.config);
            }
          } catch (refreshError) {
            // If refresh fails, clear the tokens and redirect to login
            clearTokens();
            window.location.href = "/login"; // Adjust based on your routing
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      },
    );

    // Make the API call with the provided config
    try {
      const result = await instance(axiosConfig);
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default baseAxiosQuery;
