import React, { useEffect, useRef, useState } from "react";
import useVnpayCallbackParams from "../../hooks/useVnpayCallbackParams";
import {
  responseCodeDescriptions,
  transactionStatusDescriptions,
  VnpayTransactionStatus,
} from "../../helpers/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hook";
import { clearOrder } from "../../store/orderSlice";
import { isEmpty } from "lodash";
import { useValidatePaymentMutation } from "../../store/api/consumerApi";
import { s3ImageUrl } from "../../utils/FileUtils";
import { useLanguage } from "../../hooks/useLanguage";

const PaymentResultPage: React.FC = () => {
  const location = useLocation();
  const { t } = useLanguage();
  const vnpayCallbackParams = useVnpayCallbackParams();
  const [shouldShowMore, setShouldShowMore] = useState(false);
  const isPaymentSuccess =
    vnpayCallbackParams.vnp_TransactionStatus ===
    VnpayTransactionStatus.Success;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isNotInPaymentProgress = isEmpty(
    vnpayCallbackParams.vnp_TransactionStatus,
  );
  const [validatePayment] = useValidatePaymentMutation();
  const updatePaymentRef = useRef(false);

  useEffect(() => {
    // Update payment status to BE
    if (
      vnpayCallbackParams?.vnp_TransactionStatus &&
      !updatePaymentRef.current
    ) {
      updatePaymentRef.current = true;
      validatePayment(location.search.substring(1));
    }
  }, [vnpayCallbackParams]);

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <div className="flex h-screen max-w-screen-sm flex-col items-center justify-center p-4 xl:pt-20">
        <p
          className={`whitespace-pre-line text-center text-24px font-semibold text-brand-4`}
        >
          {isPaymentSuccess ? t("orderSuccessTitle") : t("orderFailedTitle")}
        </p>
        {!isPaymentSuccess && (
          <p
            className={`mt-2 whitespace-pre-line text-center text-14px font-semibold text-neutrals-grey`}
          >
            {t("orderFailedSubtitle")}
          </p>
        )}
        <img
          src={s3ImageUrl(
            isPaymentSuccess ? "san_order_success.png" : "san_order_failed.png",
          )}
          alt=""
        />
        <button
          className={`mt-4 w-full rounded-[40px] border-2 border-solid bg-brand-1 py-3`}
          onClick={() => {
            if (isPaymentSuccess) {
              dispatch(clearOrder());
              navigate("/");
            } else {
              // re-order
            }
          }}
        >
          <p className={`text-14px font-semibold text-white`}>
            {isPaymentSuccess ? t("home") : t("reorder")}
          </p>
        </button>
        <button
          className={`mt-2 w-full rounded-[40px] border-2 border-solid bg-white py-3`}
          onClick={() => {
            if (isPaymentSuccess) {
              // check order and bill
              navigate("/order-detail");
              dispatch(clearOrder());
            } else {
              // navigate to Home
              navigate("/");
            }
          }}
        >
          <p className={`text-14px font-semibold text-brand-4`}>
            {isPaymentSuccess ? t("checkBillDetail") : t("backToHome")}
          </p>
        </button>
        <p
          className={`mt-6 whitespace-pre-line text-center text-12px font-normal text-tertiary`}
        >
          {t("registerSuggestion")}
        </p>
        <button>
          <p
            className={`mt-2 text-center text-12px font-semibold text-brand-1`}
          >
            {t("registerAccount")}
          </p>
        </button>
      </div>
    </div>
  );
};

export default PaymentResultPage;
