import { Gap } from "components/gap/Gap";
import PageHeader from "components/PageHeader";
import SanButton from "components/SanButton";
import { useLanguage } from "hooks/useLanguage";
import { useNavigate } from "react-router-dom";
import { s3ImageUrl } from "utils/FileUtils";

type NotLoginPageProps = {};

export function NotLoginPage(props: NotLoginPageProps) {
  const { t } = useLanguage();
  const navigate = useNavigate();
  return (
    <div>
      <PageHeader title={t("profile")} />
      <div className="flex h-screen flex-col items-center px-4 pt-20">
        <img
          src={s3ImageUrl("san_login.png")}
          alt=""
          className="h-auto w-4/5 md:w-1/2"
        />
        <Gap size={"M"} />
        <p className="px-12 text-center text-16px font-medium text-brand-4">
          {t("notLogin")}
        </p>
        <Gap size={"MS"} />
        <SanButton
          title={t("login")}
          onClick={() => {
            navigate("/login");
          }}
        />
      </div>
    </div>
  );
}
