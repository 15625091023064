import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type User = {};
type AuthState = {
  isLogin: boolean;
  user: User;
  loading: boolean;
  stInfo:
    | {
        deviceId: string;
        preAuthSessionId: string;
      }
    | undefined;
  jwt: string;
  isGuest: boolean;
};

const initialState: AuthState = {
  isLogin: false,
  user: {},
  loading: false,
  stInfo: undefined,
  jwt: "",
  isGuest: true,
};

export const authSlice = createSlice({
  name: "bill",
  initialState,
  reducers: {
    setLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setStInfo: (state, action: PayloadAction<any>) => {
      state.stInfo = action.payload;
    },
    setJwt: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload;
    },
    setIsGuest: (state, action: PayloadAction<boolean>) => {
      state.isGuest = action.payload;
    },
  },
});

export const { setLogin, setStInfo, setJwt, setIsGuest } = authSlice.actions;

export default authSlice.reducer;
