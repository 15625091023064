import ChevronRight from "assets/icons/chevron-right";
import { Gap } from "components/gap/Gap";
import SanButton from "components/SanButton";
import { useLanguage } from "hooks/useLanguage";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hook";
import { s3ImageUrl } from "utils/FileUtils";
import { NotLoginPage } from "./NotLoginPage";
import { useProfileItem } from "./useProfileItem";
import { setIsGuest, setLogin, setStInfo } from "store/authSlice";
import Session from "supertokens-web-js/recipe/session";
import { clearGuestToken, clearTokens } from "store/api/baseAxiosQuery";

const ProfilePage: React.FC = () => {
  const { t } = useLanguage();
  const { isLogin } = useAppSelector((root) => root.auth);
  const dispatch = useAppDispatch();

  const [isLogoutLoading, setIsLogoutLoading] = useState(false);

  const { profileMenus, generalMenus } = useProfileItem();

  return !isLogin ? (
    <NotLoginPage />
  ) : (
    <div className="h-screen w-screen overflow-hidden bg-brand">
      {/* Profile info */}
      <div className="flex flex-col items-center justify-between p-4 pt-20">
        <img
          src="https://i.pravatar.cc/300?img=3"
          alt=""
          className="h-[72px] w-[72px] rounded-full"
        />
        <p className="mt-2 text-16px font-bold text-white">Nguyễn Ngọc Lâm</p>
        <p className="mt-1 text-12px font-semibold text-brand-3 underline underline-offset-2">
          {t("edit")}
        </p>
      </div>
      <div className="h-full w-full rounded-t-[16px] bg-white px-4 py-1">
        <p className="mt-2 text-14px font-semibold text-brand-4">Tài khoản</p>
        <div className="mt-2 rounded-2xl border-[1px] border-solid border-border-1 px-3">
          {profileMenus.map((menu, index) => (
            <div
              key={`profile-${menu.id}-${index}`}
              className={`flex flex-row items-center justify-between py-3 ${index < profileMenus.length - 1 ? "border-b-[1px]" : ""} border-solid border-border-1`}
            >
              <div className="flex flex-row items-center">
                <img
                  src={s3ImageUrl(menu.icon)}
                  alt=""
                  className="h-[24px] w-[24px]"
                />
                <p className="ml-2 text-14px font-normal text-brand-4">
                  {menu.name}
                </p>
              </div>
              <ChevronRight color="#9F2B2B" />
            </div>
          ))}
        </div>
        <Gap size={"M"} />
        <p className="mt-2 text-14px font-semibold text-brand-4">
          Thông tin chung
        </p>
        <div className="mt-2 rounded-2xl border-[1px] border-solid border-border-1 px-3">
          {generalMenus.map((menu, index) => (
            <div
              key={`profile-${menu.id}-${index}`}
              className={`flex flex-row items-center justify-between py-3 ${index < generalMenus.length - 1 ? "border-b-[1px]" : ""} border-solid border-border-1`}
            >
              <div className="flex flex-row items-center">
                <img
                  src={s3ImageUrl(menu.icon)}
                  alt=""
                  className="h-[24px] w-[24px]"
                />
                <p className="ml-2 text-14px font-normal text-brand-4">
                  {menu.name}
                </p>
              </div>
              <ChevronRight color="#9F2B2B" />
            </div>
          ))}
        </div>
        <Gap size={"M1"} />
        <SanButton
          title={t("logout")}
          loading={isLogoutLoading}
          onClick={async () => {
            setIsLogoutLoading(true);
            try {
              await Session.signOut();
              dispatch(setStInfo(undefined));
              dispatch(setLogin(false));
              dispatch(setIsGuest(true));
              clearGuestToken();
              clearTokens();
            } catch (error) {
              console.log("logout error", error);
            } finally {
              setIsLogoutLoading(false);
            }
          }}
          type="outline"
        />
      </div>
    </div>
  );
};

export default ProfilePage;
