import {
  calculateItemTotalPrice,
  formatCurrency,
} from "../../utils/ConcurencyUtils";
import { useAppSelector } from "../../store/hook";
import { Order } from "../../store/type";
import { cartItemsTotalPrice } from "../../contexts/cart/cart.reducer";
import ChevronRight from "../../assets/icons/chevron-right";
import { useLanguage } from "../../hooks/useLanguage";
import { closeBottomSheet } from "../../store/bottomSheetSlice";
import { useState } from "react";

import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { s3ImageUrl } from "../../utils/FileUtils";
import { Gap } from "../gap/Gap";

export interface PaymentMethodSectionProps {
  className?: string;
}

export default function PaymentMethodSection(props: PaymentMethodSectionProps) {
  const { t } = useLanguage();
  const [open, setOpen] = useState(false);
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState(0);

  const paymentMethods = [
    {
      id: 1,
      name: "VNPay",
      logo: "san-logo.png",
    },
    {
      id: 2,
      name: t("cash"),
      logo: "san-logo.png",
    },
  ];

  return (
    <div
      className={`flex flex-col rounded-lg bg-white p-4 ${props.className || ""}`}
    >
      <div>
        <p className={`text-16px font-bold text-brand-4`}>
          {t("paymentMethod")}
        </p>
      </div>
      <div
        className="flex flex-row justify-between py-2"
        onClick={() => setOpen(!open)}
      >
        <div className={`flex flex-row items-center justify-start pt-2`}>
          <img
            className={`h-8 w-8 rounded-full`}
            src={s3ImageUrl(paymentMethods?.[selectedPaymentIndex].logo)}
            alt=""
          />
          <p className={`ml-2 text-14px font-normal text-gray-menu`}>
            {paymentMethods?.[selectedPaymentIndex].name}
          </p>
        </div>
        <div
          className={
            "flex cursor-pointer flex-row items-center justify-between"
          }
        >
          <ChevronRight color="#9F2B2B" width={"10px"} height={"12px"} />
        </div>
      </div>
      <BottomSheet
        open={open}
        expandOnContentDrag
        onDismiss={() => {
          setOpen(false);
        }}
      >
        <div className={`flex h-fit w-full flex-col items-center px-2`}>
          <div className="max-w-screen-md w-full">
            <p className={`text-center text-16px font-bold text-brand-4`}>
              {t("paymentMethod")}
            </p>
            <Gap size={"M"} />
            {paymentMethods.map((item, index) => (
              <div
                key={index}
                className={`flex flex-row items-center justify-start px-4 py-2
                ${index === selectedPaymentIndex ? "rounded-[16px] border-[1px] border-primary" : ""}`}
                onClick={() => {
                  setSelectedPaymentIndex(index);
                }}
              >
                <img
                  className={`h-8 w-8 rounded-full`}
                  src={s3ImageUrl(item.logo)}
                  alt=""
                />
                <p className={`ml-2 text-14px font-normal text-gray-menu`}>
                  {item.name}
                </p>
              </div>
            ))}
            <Gap size={"M"} />
            <div className={`w-full px-2 pb-4`}>
              <button
                className={`w-full rounded-[40px] bg-brand-1 px-8 py-[6px]`}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <p className={`text-14px font-semibold text-white`}>
                  {t("agree")}
                </p>
              </button>
            </div>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
}
