type DiscountSelectionPageProps = {
  discountCode?: string;
  onDiscard?: () => void;
  onApply?: () => void;
};

export default function DiscountSelectionPage({
  discountCode,
  onDiscard,
  onApply,
}: DiscountSelectionPageProps) {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <h1 className="text-2xl mb-4 font-bold">Discount Code</h1>
      <input
        type="text"
        value={discountCode}
        onChange={(e) => {}}
        className="mb-4 rounded-md border border-gray-300 px-4 py-2"
      />
      <div className="flex w-full justify-between">
        <button
          onClick={onDiscard}
          className="rounded bg-gray-300 px-4 py-2 font-bold text-white hover:bg-gray-400"
        >
          Discard
        </button>
        <button
          onClick={onApply}
          className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
        >
          Apply
        </button>
      </div>
    </div>
  );
}
