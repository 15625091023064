import ChevronLeft from "../assets/icons/chevron-left";
import { useNavigate } from "react-router-dom";

type PageHeaderProps = {
  title?: string;
  IconRight?: React.FC<{ className?: string; color?: string }>;
  onIconRightClick?: () => void;
  showBackButton?: boolean;
  transparent?: boolean;
  titleColor?: string;
};

export default function PageHeader(props: PageHeaderProps) {
  const navigate = useNavigate();
  const { IconRight, onIconRightClick, showBackButton = true } = props;
  return (
    <header
      className={`fixed flex h-[58px] md:h-[70px] w-screen max-w-screen-md flex-row items-center justify-between ${props.transparent ? "bg-transparent" : "bg-white"} px-4`}
    >
      {showBackButton ? (
        <div
          onClick={() => {
            navigate(-1);
          }}
          className="flex h-9 w-9 cursor-pointer items-center justify-center"
        >
          <ChevronLeft color={"#31261D"} />
        </div>
      ) : (
        <div className="h-9 w-9" />
      )}
      {props?.title && (
        <span
          className={`text-[16px] font-bold text-brand-4`}
          style={{ color: props.titleColor }}
        >
          {props?.title}
        </span>
      )}
      <div className={`flex h-9 w-9 items-center justify-center`}>
        {IconRight && <IconRight />}
      </div>
    </header>
  );
}
