import { createApi } from "@reduxjs/toolkit/query/react";
import {
  CreateOrderRequestParams,
  CreateOrderResponse,
  ValidatePaymentResponse,
  UpdatePaymentRequestParams,
  UpdatePaymentResponse,
  GetBillResponse,
} from "../type";
import { v4 as uuidv4 } from "uuid";
import isDev from "../../utils/Helper";
import baseAxiosQuery from "./baseAxiosQuery";

export const consumerApi = createApi({
  reducerPath: "consumerApi",
  baseQuery: baseAxiosQuery({
    baseUrl: `${process.env.REACT_APP_CONSUMER_BASE_URL}/v1` || "",
  }),
  endpoints: (builder) => ({
    getMe: builder.query<any, void>({
      query: () => ({
        url: "/get_me",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "get_me"
        },
      }),
    }),
    createOrder: builder.mutation<
      CreateOrderResponse,
      CreateOrderRequestParams
    >({
      query: (requestParams) => ({
        url: "/create_bill",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "create_bill",
          params: {
            order: requestParams.order,
            locale: requestParams.locale,
            is_local: isDev(),
            local_url: "http://localhost:3000/payment-result",
          },
        },
      }),
      // remove "id" and "jsonrpc" from original response
      transformResponse: (baseQueryReturnValue: any) =>
        baseQueryReturnValue?.result,
    }),
    getBill: builder.mutation<GetBillResponse, string>({
      query: (billId) => ({
        url: "/get_bill",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "get_bill",
          params: {
            id_: billId,
          },
        },
      }),
    }),
    updatePayment: builder.mutation<
      UpdatePaymentResponse,
      UpdatePaymentRequestParams
    >({
      query: (requestParams) => ({
        url: "/update_payment",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "update_payment",
          params: {
            params: requestParams,
          },
        },
      }),
    }),
    validatePayment: builder.mutation<ValidatePaymentResponse, string>({
      query: (rawQueryParamString) => ({
        url: "/validate_payment",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "validate_payment",
          params: {
            raw: rawQueryParamString,
          },
        },
      }),
    }),
  }),
});

export const {
  useCreateOrderMutation,
  useUpdatePaymentMutation,
  useValidatePaymentMutation,
  useGetBillMutation,
  useGetMeQuery,
} = consumerApi;
