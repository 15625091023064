import { useLanguage } from "hooks/useLanguage";
import { useProfileItem } from "pages/ProfilePage/useProfileItem";
import { useNavigate } from "react-router-dom";
import { s3ImageUrl } from "utils/FileUtils";

type HomeUtilitiesProps = {};

const HomeUtilities = (props: HomeUtilitiesProps) => {
  const { t } = useLanguage();
  const { profileMenus } = useProfileItem();
  const navigate = useNavigate();
  return (
    <div className="flex h-[132px] w-full flex-col rounded-2xl  bg-white p-4 shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
      <div className="flex flex-row justify-between border-b-[1px] border-dashed border-border-1 pb-3">
        <div className="flex flex-row gap-1">
          <img src={s3ImageUrl(`san_medal.png`)} className="h-5 w-5" alt="" />
          <p className="text-14px font-semibold text-brand-4">
            {t("sanRewards")}
          </p>
        </div>
        <p className="text-16px font-semibold text-brand">32</p>
      </div>
      <div className="mt-2 flex flex-row justify-around">
        {profileMenus.map((menu, index) => (
          <div
            onClick={() => {
              navigate(menu.path);
            }}
            key={`home-utils-${menu.id}-${index}`}
            className={`flex flex-col items-center justify-center py-3`}
          >
            <img
              src={s3ImageUrl(menu.icon)}
              alt=""
              className="h-[24px] w-[24px]"
            />
            <p className="text-12px font-normal text-gray-menu">
              {menu.shortName}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeUtilities;
