import * as React from "react";

const CheckBoxIcon = ({ selected, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#9F2B2B"
      d="M2.5 6A3.5 3.5 0 0 1 6 2.5h12A3.5 3.5 0 0 1 21.5 6v12a3.5 3.5 0 0 1-3.5 3.5H6A3.5 3.5 0 0 1 2.5 18V6Z"
    />
    {selected && (
      <path
        fill="none"
        stroke="#9F2B2B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 12l3 3 7-7"
      />
    )}
  </svg>
);

export default CheckBoxIcon;
