const development: boolean = process.env.NODE_ENV === "development";

export default function isDev(): boolean {
  return development;
}

export const scrollContent = (scrollRef: any, divId: string) => {
  const { current } = scrollRef;
  const osInstance = current?.osInstance();
  if (!osInstance) {
    return;
  }
  const { scrollOffsetElement } = osInstance.elements();
  const element = document.getElementById(divId);
  const position = element ? element.getBoundingClientRect().top + window.scrollY : 0;
  scrollOffsetElement.scrollTo({
    behavior: "smooth",
    top: position
  });
};

export const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};

const isPhoneNumber = (phoneNumber: string) => {
  return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phoneNumber);
};

export const validatePhoneNumber = (phoneNumber: string) => {
  return isPhoneNumber(phoneNumber);
};
