type GapSize = "XXS" | "XS" | "S" | "M" | "MS" | "M1" | "L" | "XL" | "XXL";
type GapProps = {
  size: GapSize;
  ref?: any;
}

export function Gap(props: GapProps) {
  const classNameBySize = {
    XXS: `h-1 md:h-2 lg:h-3`,
    XS: `h-2 md:h-3`,
    S: `h-3 md:h-4`,
    M: `h-4 md:h-5`,
    "MS": `h-8 md:h-12`,
    "M1": `h-[60px] md:h-20`,
    L: `h-20 md:h-28`,
    XL: `h-32 md:h-40`,
    XXL: `h-40 md:h-64`
  };
  return <div ref={props?.ref} className={`w-full ${classNameBySize?.[props.size as GapSize]}`} />;
}
