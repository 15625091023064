import PageHeader from "components/PageHeader";

type FavoritePageProps = {};

export default function FavoritePage(props: FavoritePageProps) {
  return (
    <div className="flex h-screen w-screen flex-col bg-default-1">
      <PageHeader title={"Favorite"} />
    </div>
  );
}
