import React, { useState, ComponentType } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { AlertColor, SnackbarContent } from "@mui/material";

export type SnackBarType = "info" | "error" | "success" | "warning";
export type WithSnackbarProps = {
  showSnackbar?: (params: {
    message: string;
    type?: SnackBarType;
    icon?: React.ReactNode;
  }) => void;
};

const mapping = {
  info: {
    bgColor: "#583432",
    textColor: "#F0F0F0",
  },
  error: {
    bgColor: "#ed3a3a",
    textColor: "#F0F0F0",
  },
  success: {
    bgColor: "#387a2a",
    textColor: "#F0F0F0",
  },
  warning: {
    bgColor: "#f9b509",
    textColor: "#F0F0F0",
  },
};

export function withSnackbar<T extends object>(
  WrappedComponent: ComponentType<T>,
) {
  const WithSnackbar: React.FC<T> = (props) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [icon, setIcon] = useState<React.ReactNode>(null);
    const [type, setType] = useState<SnackBarType>("info");

    const showSnackbar = (params: {
      message: string;
      type?: SnackBarType;
      icon?: React.ReactNode;
    }) => {
      const { message, icon, type = "info" } = params;
      setMessage(message);
      setIcon(icon);
      setType(type);
      setSnackbarOpen(true);
    };

    const handleClose = () => {
      setSnackbarOpen(false);
    };

    const bgColor = mapping[type].bgColor;
    const textColor = mapping[type].textColor;

    return (
      <>
        <WrappedComponent {...(props as T)} showSnackbar={showSnackbar} />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackbarOpen}
          sx={{ bottom: 100 }}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <div
            style={{ backgroundColor: bgColor }}
            className={`rounded-lg px-4 py-2`}
          >
            <p
              style={{ color: textColor }}
              className={`text-center text-12px font-semibold`}
            >
              {message}
            </p>
          </div>
        </Snackbar>
      </>
    );
  };

  return WithSnackbar;
}
