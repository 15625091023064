import { Product } from "../../models/Product";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { openProductDetail } from "../../store/bottomSheetSlice";
import { useLanguage } from "../../hooks/useLanguage";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import IconPlus from "assets/icons/icon-plus";
import RemoteLottie from "components/RemoteLottie";
import { r2ImageUrl, s3ImageUrl } from "utils/FileUtils";
import HoverVideo from "components/HoverVideo";

export interface MenuProductItemProps {
  product: Product;
  index?: number;
}

export default function MenuProductItem(props: MenuProductItemProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { product, index = 0 } = props;
  const { language } = useLanguage();
  const { image, name, pricing, id } = product || {};
  const goToProductDetailPage = () => {
    navigate(`/product/${id}`);
  };

  const currentOrder = useAppSelector((root) => root.order.currentOrder);
  const currentItems = currentOrder?.items || [];

  const numOfVariants = useRef(0);

  const quantity = currentItems.reduce((total, item) => {
    if (item.item_id === id) {
      numOfVariants.current++;
      return item.quantity + total;
    }
    return total;
  }, 0);

  const renderAddButton = () => {
    return (
      <button
        onClick={(e) => {
          e.stopPropagation();
          dispatch(openProductDetail(product));
        }}
        className={`z-0 flex h-7 w-7 flex-row items-center justify-center rounded-full border-[1px] border-solid border-brand-shape-3 bg-brand py-1 hover:scale-105 hover:bg-brand-tint focus:outline-none`}
      >
        <IconPlus color="#fff" />
      </button>
    );
  };

  const renderQuantity = () => {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          dispatch(openProductDetail(product));
        }}
        className={`flex h-7 w-7 flex-row items-center justify-center rounded-full border-[1px] border-solid border-brand-shape-3 bg-white pt-0.5 hover:scale-105 hover:border-brand-shape-3 focus:outline-none`}
      >
        <span className={`text-12px font-normal text-brand-4 hover:text-brand`}>
          {quantity}
        </span>
      </div>
    );
  };

  return (
    <div className=" ml-4 mt-4 w-[148px]">
      <div
        onClick={goToProductDetailPage}
        className="relative h-[168px] w-[148px] overflow-hidden rounded-[16px]"
      >
        {/* <RemoteLottie
          animationUrl={r2ImageUrl("1.json")}
          width={148}
          height={168}
        /> */}
        <HoverVideo
          videoUrl={s3ImageUrl(`${index + 1}.mp4`)}
          // placeholderImg={image}
          autoplay={false}
          loop={false}
          className="h-[168px] w-[148px] object-cover object-center"
        />
        {/* <img
          src={image}
          className={`h-[168px] w-[148px] object-cover`}
          alt=""
        /> */}
        <div className="absolute bottom-2 right-2 z-0">
          {quantity > 0 ? renderQuantity() : renderAddButton()}
        </div>
      </div>
      <div
        onClick={goToProductDetailPage}
        className={`mt-2 flex w-full flex-row justify-between`}
      >
        <span
          className={`line-clamp-1 w-3/4 text-14px font-normal text-brand-4`}
        >
          {name?.[language]}
        </span>
        <span className={`text-14px font-normal text-brand-1`}>
          {pricing?.[0].display}
        </span>
      </div>
      {/* <button
        onClick={() => {
          dispatch(openProductDetail(product));
        }}
        className={`focus:outline-none" mt-2 w-full rounded-[40px] border-[1px] border-solid border-brand-shape-3 bg-white py-1 hover:bg-brand-shape-3`}
      >
        Order
      </button> */}
    </div>
  );
}
