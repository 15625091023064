import Slider from "@mui/material/Slider";
import { convertContentToPercent } from "models/Product";

interface ItemSliderProps {
  title: string;
  value: number;
  labels: number[];
  onChangeValue: (value: number) => void;
  disabled?: boolean;
}

const ItemSlider = ({
  title,
  labels,
  value,
  onChangeValue,
  disabled = false,
}: ItemSliderProps) => {
  const marks = labels.map((item) => {
    return {
      value: item,
      label:
        item === value ? (
          <div
            className={`${item === 0 && "ml-4"} ${item === 100 && "mr-8"} rounded-lg bg-neutrals-divider px-1.5 py-1 text-brand-4`}
          >
            {`${item}%`}
          </div>
        ) : null,
    };
  });

  return (
    <div className="w-full overflow-hidden">
      <div className="flex justify-center">
        <Slider
          value={value}
          disabled={disabled}
          aria-label="Restricted values"
          onChange={(_event: Event, value: number | number[]) => {
            typeof value === "number" && onChangeValue(value);
          }}
          step={null}
          sx={{
            width: "95%",
            height: 10,
            color: "#9F2B2B",
            "& .MuiSlider-thumb": {
              border: "2px solid #fff",
              "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                boxShadow: "inherit",
              },
            },
            "& .MuiSlider-rail": {
              color: "#F0F0F0",
            },
            "& .MuiSlider-mark, &.MuiSlider-markActive": {
              color: "#fff",
              width: "6px",
              height: "6px",
              borderRadius: "50%",
            },
          }}
          marks={marks}
        />
      </div>
    </div>
  );
};

export default ItemSlider;
