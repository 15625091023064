import { useNavigate } from "react-router-dom";
import { s3ImageUrl } from "../../utils/FileUtils";

export default function LoginToUnlockSection() {
  const navigate = useNavigate();
  return (
    <div className="row relative flex justify-between rounded-[16px] bg-secondary-4 p-4 pr-24">
      <div className="flex flex-col items-start justify-center">
        <p className={`text-14px font-bold text-brand-4`}>
          Nhận ưu đãi độc quyền
        </p>
        <p
          className={`mt-2 text-12px font-normal leading-[16px] text-brand-4`}
        >{`
      Đăng ký ngay để mở khóa hàng loạt tính năng 
      độc quyền và nhận ưu đãi hấp dẫn chỉ dành cho tài khoản mới!`}</p>
        <button
          onClick={() => {
            navigate("/login");
          }}
          className={`mt-4 rounded-[40px] bg-white px-4 py-2 text-brand-4`}
        >
          Đăng ký/ Đăng nhập
        </button>
      </div>
      <div className={`absolute bottom-0 right-0 top-0`}>
        <img src={s3ImageUrl(`san_logo_mark.png`)} className="h-full" alt="" />
      </div>
    </div>
  );
}
