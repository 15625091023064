import { Gap } from "components/gap/Gap";
import { MenuWithCategory } from "components/menu/MenuWithCategory";
import { MenuWithCategorySkeleton } from "components/menu/MenuWithCategorySkeleton";
import { CategoryProduct, DEFAULT_CATEGORY } from "models/Product";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetMenuQuery } from "store/api/menuApi";
import { useAppDispatch, useAppSelector } from "store/hook";
import { setAllItems, setCategories, setToppings } from "store/itemSlice";
import {
  DEFAULT_COUNTRY,
  DEFAULT_LOCATION,
  DEFAULT_MENU,
  setSession,
} from "store/sessionSlice";
import { indexAllItems } from "utils/StoreUtils";

const MenuPage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const storedCategories = useAppSelector((root) => root.allItems.categories);
  const [activeCategories, setActiveCategories] = useState(storedCategories);

  useEffect(() => {
    const country = searchParams.get("c") || DEFAULT_COUNTRY;
    const location = searchParams.get("l") || DEFAULT_LOCATION;
    const table = searchParams.get("t") || undefined;
    dispatch(
      setSession({
        country,
        location,
        table,
      }),
    );
  }, []);

  const [allToppings, setAllToppings] = useState<CategoryProduct>();

  const [selectedCategory, setSelectedCategory] =
    useState<CategoryProduct>(DEFAULT_CATEGORY);

  const {
    data: categories = [],
    error,
    isLoading,
    isSuccess,
  } = useGetMenuQuery({
    country: DEFAULT_COUNTRY,
    location: DEFAULT_LOCATION,
    version: DEFAULT_MENU,
  });

  useEffect(() => {
    if (isSuccess && categories) {
      dispatch(setCategories(categories));
      dispatch(setAllItems(indexAllItems(categories as CategoryProduct[])));
      dispatch(setToppings(categories[categories.length - 1]));
      // update active categories to re-render
      setActiveCategories(categories as CategoryProduct[]);
    }
  }, [isSuccess, categories, dispatch]);

  // Remove last categories (toppings)
  const selectableProductsCategories = activeCategories?.slice(0, -1) || [];

  useEffect(() => {
    const TOPPING_INDEX = categories?.length - 1;
    const toppings = categories?.[TOPPING_INDEX];
    setAllToppings(toppings);
    setSelectedCategory(categories[0]);
  }, [categories]);

  return (
    <div className="">
      <Gap size={"L"} />
      <div className={`flex w-full flex-col gap-6 z-0`}>
        {isLoading
          ? Array(3)
              .fill(null)
              .map((item, index) => (
                <MenuWithCategorySkeleton key={`${index}-menu-item`} />
              ))
          : selectableProductsCategories.map((category, index) => (
              <MenuWithCategory
                key={`${index}-${category.name}`}
                categoryName={category.name}
                products={category.items}
              />
            ))}
      </div>
    </div>
  );
};

export default MenuPage;
