import React, { useRef, useState } from "react";

interface VideoComponentProps {
  videoUrl: string;
  className?: string;
  autoplay?: boolean;
  loop?: boolean;
}

const HoverVideo: React.FC<VideoComponentProps> = ({
  videoUrl,
  className = "",
  loop,
  autoplay,
}) => {
  const videoRef = useRef<any>();

  const [loopState, setLoopState] = useState(loop)

  const handleHoverStart = () => {
    videoRef.current?.play();
    setLoopState(true);
  };

  const handleHoverEnd = () => {
    videoRef.current?.pause();
    setLoopState(false);
  };

  return (
    <div
      className={`relative flex items-center justify-center overflow-hidden ${className}`}
      onMouseEnter={handleHoverStart} // Desktop hover start
      onMouseLeave={handleHoverEnd} // Desktop hover end
      onTouchStart={handleHoverStart} // Mobile touch start
      onTouchEnd={handleHoverEnd} // Mobile touch end
    >
      <video
        src={videoUrl}
        className="h-full w-full object-cover"
        muted
        ref={videoRef}
        loop={loopState}
        autoPlay={autoplay}
        playsInline
      />
    </div>
  );
};

export default HoverVideo;
