import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import Passwordless from "supertokens-auth-react/recipe/passwordless";
import Session from "supertokens-auth-react/recipe/session";
import "./i18n.ts";

SuperTokens.init({
  appInfo: {
    apiDomain: process.env.REACT_APP_CONSUMER_BASE_URL || "",
    apiBasePath: "/auth",
    appName: "san-consumer-app",
    websiteDomain: "http://localhost:3000",
  },
  recipeList: [
    Session.init({
      tokenTransferMethod: "header",
    }),
    Passwordless.init({
      contactMethod: "EMAIL_OR_PHONE",
    }),
  ],
  // enableDebugLogs: true,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <SuperTokensWrapper>
    <App />
  </SuperTokensWrapper>,
);
