import React from "react";
import TableSection from "../../components/order/TableSection";
import TimeEstimationSection from "../../components/order/TimeEstimationSection";
import { Gap } from "../../components/gap/Gap";
import MyOrderSection from "../../components/order/MyOrderSection";
import TotalPriceSection from "../../components/order/TotalPriceSection";
import { useAppSelector } from "../../store/hook";
import { cartItemsTotalPrice } from "../../contexts/cart/cart.reducer";
import PaymentMethodSection from "components/order/PaymentMethodSection";
import { s3ImageUrl } from "utils/FileUtils";
import { useLanguage } from "hooks/useLanguage";

const OrdersPage: React.FC = () => {
  const currentOrder = useAppSelector((root) => root.order.currentOrder);
  const totalPrice = cartItemsTotalPrice(currentOrder?.items || []);
  const { t } = useLanguage();
  return (
    <div className="h-full w-screen max-w-screen-md bg-default-1 p-4">
      <Gap size={"M1"} />
      <TableSection />
      {currentOrder?.items?.length === 0 ? (
        <div className="flex flex-col justify-center items-center">
          <Gap size={"L"} />
          <img src={s3ImageUrl("san_empty.png")} alt="" className="mx-auto w-1/2 md:w-1/3" />
          <Gap size={"M"} />
          <p className="text-14px font-semibold text-brand-4">{t("noOrder")}</p>
          <Gap size={"XXS"} />
          <p className="text-12px font-normal text-gray-menu text-center">{t("emptyStateDescription")}</p>
        </div>
      ) : (
        <div>
          <Gap size={"S"} />
          <TimeEstimationSection />
          <Gap size={"S"} />
          <MyOrderSection items={currentOrder?.items} />
          <Gap size={"S"} />
          <TotalPriceSection total={totalPrice} subTotal={totalPrice} />
          <Gap size={"S"} />
          <PaymentMethodSection />
          <Gap size={"M"} />
          <Gap size={"XL"} />
        </div>
      )}
    </div>
  );
};

export default OrdersPage;
