const IconRadio = (props: {
  $isActive?: boolean;
  color?: string;
  width?: number;
  height?: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path fill="#fff" d="M1.25 10a9.5 9.5 0 1 1 19 0 9.5 9.5 0 0 1-19 0Z" />
    <path
      stroke="#9F2B2B"
      d="M1.25 10a9.5 9.5 0 1 1 19 0 9.5 9.5 0 0 1-19 0Z"
    />
    {props?.$isActive && <circle cx={10.75} cy={10} r={6} fill="#9F2B2B" />}
  </svg>
);
export default IconRadio;
