import { formatCurrency } from "../../utils/ConcurencyUtils";
import { useAppSelector } from "../../store/hook";
import { BillItem } from "store/type";
import { generateCustomizeDescription } from "utils/OrderUtils";

export interface OrderItemProps {
  item: BillItem;
  showDivide: boolean;
}

export default function MyBillItem(props: OrderItemProps) {
  const { item, showDivide } = props || {};
  const { size, children, price, quantity, item_id, item_name } = item || {};
  const allItems = useAppSelector((root) => root.allItems.allItems);
  const itemInMenu = allItems?.[item_id];
  const description = generateCustomizeDescription(item);

  return (
    <div className="w-full mt-2">
      <div className={`flex w-full flex-row items-center justify-between`}>
        <img
          src={itemInMenu?.image || ""}
          alt=""
          className={`h-14 w-14 min-w-14 rounded-lg`}
        />
        <div className={`flex w-full flex-col justify-start min-h-14 ml-2 -mt-2 items-start`}>
          <div className="flex w-auto flex-row justify-between gap-2">
            <p className={`line-clamp-1 text-14px font-normal text-brand-4`}>
              {item_name}
            </p>
          </div>
          <p className={`text-12px font-normal text-tertiary`}>{description}</p>
          <p className={`text-12px font-normal text-brand-1`}>
            {/* {formatCurrency(price)} */}
          </p>
        </div>
        <div className="flex flex-col items-end">
          <p className="text-14px font-normal text-tertiary">{`${formatCurrency(price)}`}</p>
          <p className="text-12px font-normal text-tertiary">{`x${quantity}`}</p>
          <p className="text-12px font-normal text-brand-1">{`${formatCurrency(price * quantity)}`}</p>
        </div>
      </div>
      {showDivide && <div className={`mt-2 h-[1px] w-full bg-[#FAFAFA]`} />}
    </div>
  );
}
