import TableSection from "../../components/order/TableSection";
import { Gap } from "../../components/gap/Gap";
import TimeEstimationSection from "../../components/order/TimeEstimationSection";
import MyOrderSection from "../../components/order/MyOrderSection";
import TotalPriceSection from "../../components/order/TotalPriceSection";
import PageHeader from "../../components/PageHeader";
import { useAppSelector } from "../../store/hook";
import { cartItemsTotalPrice } from "../../contexts/cart/cart.reducer";
import { formatCurrency } from "../../utils/ConcurencyUtils";
import PaymentMethodSection from "../../components/order/PaymentMethodSection";
import { useLanguage } from "../../hooks/useLanguage";
import { Link } from "react-router-dom";
import MyBillItem from "components/order/MyBillItem";
import MyBillSection from "components/order/MyBillSection";

interface CheckoutPageProps {}

export default function CheckoutPage(props: CheckoutPageProps) {
  const { t } = useLanguage();
  const lastBill = useAppSelector((root) => root.bill.bill);
  const { items, vnp_url = "", charges } = lastBill || {};
  const { total = 0, sub_total, discount } = charges || {};
  console.log("=>(CheckoutPage.tsx:22) lastBill", lastBill);
  return (
    <div className={`relative flex h-screen flex-col items-center`}>
      <PageHeader title={t("payment")} />
      <div className="h-screen w-screen max-w-screen-md bg-default-1 p-4">
        <Gap size={"M1"} />
        <TableSection />
        <Gap size={"S"} />
        <TimeEstimationSection />
        <Gap size={"S"} />
        <MyBillSection items={items} />
        <Gap size={"S"} />
        <TotalPriceSection
          isFinal
          total={total}
          subTotal={sub_total}
          discount={`${discount}`}
        />
        <Gap size={"S"} />
        <PaymentMethodSection />
        <Gap size={"XL"} />
      </div>
      <div
        className={`fixed bottom-0 left-0 right-0 flex h-[87px] w-full flex-row justify-center rounded-t-[16px] bg-brand-1 p-4`}
      >
        <div className="w-full max-w-screen-md flex flex-row justify-between">
          <div>
            <p className={`text-12px font-normal text-white`}>
              {t("totalPayment")}
            </p>
            <p className={`text-16px font-bold text-white`}>
              {formatCurrency(total)}
            </p>
          </div>
          <Link to={vnp_url} rel="noopener noreferrer">
            <button className={`rounded-[40px] bg-white px-8 py-[6px]`}>
              <p className={`text-14px font-semibold text-brand-1`}>
                {t("payment")}
              </p>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
